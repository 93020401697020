import * as i0 from '@angular/core';
import { Pipe, EventEmitter, Component, Input, Output, ViewChild, Injectable, NgModule } from '@angular/core';
import * as i4 from '@angular/material/sort';
import { MatSort, MatSortModule } from '@angular/material/sort';
import * as i2 from '@angular/material/table';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import * as i3 from '@angular/material/paginator';
import { MatPaginator, MatPaginatorModule, MatPaginatorIntl } from '@angular/material/paginator';
import * as i1 from '@angular/common';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import * as i1$1 from '@ngx-translate/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { Subject } from 'rxjs';
function TableComponent_ng_container_1_Case_1_th_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "th", 8);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const column_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(column_r1.displayColumn);
  }
}
function TableComponent_ng_container_1_Case_1_td_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "td", 9);
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "numberSuffix");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const element_r2 = ctx.$implicit;
    const column_r1 = i0.ɵɵnextContext(2).$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind3(2, 1, element_r2[column_r1.fieldName], ctx_r2.transformationType.withCurrency, ctx_r2.currencyCode));
  }
}
function TableComponent_ng_container_1_Case_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TableComponent_ng_container_1_Case_1_th_0_Template, 2, 1, "th", 6)(1, TableComponent_ng_container_1_Case_1_td_1_Template, 3, 5, "td", 7);
  }
}
function TableComponent_ng_container_1_Case_2_th_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "th", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const column_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(column_r1.displayColumn);
  }
}
function TableComponent_ng_container_1_Case_2_td_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "td", 13);
    i0.ɵɵlistener("click", function TableComponent_ng_container_1_Case_2_td_1_Template_td_click_0_listener() {
      const element_r5 = i0.ɵɵrestoreView(_r4).$implicit;
      const ctx_r2 = i0.ɵɵnextContext(3);
      return i0.ɵɵresetView(ctx_r2.viewMore(element_r5));
    });
    i0.ɵɵelement(1, "img", 14);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("src", ctx_r2.tableConfiguration.img, i0.ɵɵsanitizeUrl);
  }
}
function TableComponent_ng_container_1_Case_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TableComponent_ng_container_1_Case_2_th_0_Template, 2, 1, "th", 10)(1, TableComponent_ng_container_1_Case_2_td_1_Template, 2, 1, "td", 11);
  }
}
function TableComponent_ng_container_1_Case_3_th_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "th", 12);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const column_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(column_r1.displayColumn);
  }
}
function TableComponent_ng_container_1_Case_3_td_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "td", 16);
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const element_r6 = ctx.$implicit;
    const column_r1 = i0.ɵɵnextContext(2).$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(element_r6[column_r1.fieldName]);
  }
}
function TableComponent_ng_container_1_Case_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, TableComponent_ng_container_1_Case_3_th_0_Template, 2, 1, "th", 10)(1, TableComponent_ng_container_1_Case_3_td_1_Template, 2, 1, "td", 15);
  }
}
function TableComponent_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0, 5);
    i0.ɵɵtemplate(1, TableComponent_ng_container_1_Case_1_Template, 2, 0)(2, TableComponent_ng_container_1_Case_2_Template, 2, 0)(3, TableComponent_ng_container_1_Case_3_Template, 2, 0);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    let tmp_3_0;
    const column_r1 = ctx.$implicit;
    const ctx_r2 = i0.ɵɵnextContext();
    i0.ɵɵproperty("matColumnDef", column_r1.fieldName);
    i0.ɵɵadvance();
    i0.ɵɵconditional(1, (tmp_3_0 = column_r1.dataTypeColumn) === ctx_r2.columnDataType.Currency ? 1 : tmp_3_0 === ctx_r2.columnDataType.Link ? 2 : 3);
  }
}
function TableComponent_tr_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tr", 17);
  }
}
function TableComponent_tr_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tr", 18);
  }
}
var ColumnDataType;
(function (ColumnDataType) {
  ColumnDataType["Text"] = "text";
  ColumnDataType["Number"] = "number";
  ColumnDataType["Currency"] = "currency";
  ColumnDataType["Link"] = "link";
})(ColumnDataType || (ColumnDataType = {}));
const TRANSFORMATION_TYPE = {
  withCurrency: 'currency',
  withoutCurrency: 'number'
};
const GENERIC_TABLE_MAPPER = [{
  fieldName: 'column1',
  displayColumn: 'column 1',
  dataTypeColumn: ColumnDataType.Text
}, {
  fieldName: 'column2',
  displayColumn: 'column 2',
  dataTypeColumn: ColumnDataType.Text
}, {
  fieldName: 'column3',
  displayColumn: 'column 3',
  dataTypeColumn: ColumnDataType.Text
}, {
  fieldName: 'column4',
  displayColumn: 'column 4',
  dataTypeColumn: ColumnDataType.Number
}, {
  fieldName: 'column5',
  displayColumn: 'column 5',
  dataTypeColumn: ColumnDataType.Text
}];
const generic_tableConfiguration = {
  columnList: ['coulmn1', 'coulmn2', 'coulmn3', 'coulmn4', 'coulmn5'],
  mapper: GENERIC_TABLE_MAPPER,
  tableTitle: 'Generic Table Title Placeholder',
  tableType: 'GENERIC TABLE TYPE',
  pageSizeOptions: [5, 10, 20, 50],
  pageSize: 5
};
const USD = 'USD';
const LANGUAGE_EN = 'en';

// import { TRANSFORMATION_TYPE ,USD} from '../constants/app.constants';
class NumberSuffixPipe {
  transform(value, transformationType = TRANSFORMATION_TYPE.withCurrency, currencyCode = USD, ...args) {
    if (value === null || value === undefined) return null;
    let maxDecimals = 0;
    if (value > 999) {
      maxDecimals = 2;
    }
    switch (transformationType) {
      case TRANSFORMATION_TYPE.withCurrency:
        return this.currencyTransform(value, maxDecimals, currencyCode);
      case TRANSFORMATION_TYPE.withoutCurrency:
        return this.numberTransform(value, maxDecimals);
      default:
        return value;
    }
  }
  currencyTransform(value, maxDecimals, currencyCode) {
    return new Intl.NumberFormat(LANGUAGE_EN, {
      style: 'currency',
      currency: currencyCode,
      notation: "compact",
      compactDisplay: "short",
      maximumFractionDigits: maxDecimals
    }).format(value);
  }
  numberTransform(value, maxDecimals) {
    return new Intl.NumberFormat("en", {
      notation: "compact",
      compactDisplay: "short",
      maximumFractionDigits: maxDecimals
    }).format(value);
  }
  static {
    this.ɵfac = function NumberSuffixPipe_Factory(t) {
      return new (t || NumberSuffixPipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "numberSuffix",
      type: NumberSuffixPipe,
      pure: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberSuffixPipe, [{
    type: Pipe,
    args: [{
      name: 'numberSuffix'
    }]
  }], null, null);
})();
class TableComponent {
  constructor(detct) {
    this.detct = detct;
    this.tableConfiguration = generic_tableConfiguration; //Generic Data for sample table
    this.tableData = [];
    this.isFilterApplied = false;
    this.currencyCode = USD;
    this.searchedText = new EventEmitter();
    this.rowInfo = new EventEmitter();
    this.filteredTableData = []; //extra variable to prevent manipulation of tableData
    this.transformationType = TRANSFORMATION_TYPE;
    this.columnDataType = ColumnDataType;
    // currencyCode: string;
    this.customerName = '';
  }
  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.tableData);
    this.filteredTableData = this.tableData;
    this.SearchEvent$?.subscribe(v => {
      this.searchRecords(v);
    });
  }
  makeChanges() {
    this.paginator?._changePageSize(this.tableConfiguration?.pageSize);
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.searchedText.emit(null);
  }
  resetSortState() {
    this.sort.active = '';
    this.sort.direction = '';
    this.sort.sortChange.emit();
  }
  onCustomerChange() {
    this.dataSource.sort = this.sort;
    if (this.sort) {
      this.resetSortState();
      this.sortDataBySumOfValues();
    }
  }
  ngOnChanges(changes) {
    if (changes['tableData'] && changes['tableData'].previousValue !== changes['tableData'].currentValue) {
      this.tableData = changes['tableData'].currentValue;
      this.filteredTableData = this.tableData;
      this.dataSource = new MatTableDataSource(this.filteredTableData);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.onCustomerChange();
    }
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.onCustomerChange();
  }
  searchRecords(tbData) {
    this.filteredTableData = tbData;
    this.dataSource = new MatTableDataSource(this.filteredTableData);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
    this.sortDataBySumOfValues();
  }
  /**
   * Sorts the data source by the sum of pipelineValue and totalValueDelivered in descending order.
   */
  sortDataBySumOfValues() {
    this.dataSource.data = this.dataSource.data.sort((a, b) => {
      const sumA = (a.pipelineValue || 0) + (a.totalValueDelivered || 0);
      const sumB = (b.pipelineValue || 0) + (b.totalValueDelivered || 0);
      return sumB - sumA; // Sort in descending order by sum
    });
  }
  /**To open the Modal */
  viewMore(rowInfo) {
    this.rowInfo.emit(rowInfo);
  }
  static {
    this.ɵfac = function TableComponent_Factory(t) {
      return new (t || TableComponent)(i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: TableComponent,
      selectors: [["lib-one-customer-table"]],
      viewQuery: function TableComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(MatSort, 5);
          i0.ɵɵviewQuery(MatPaginator, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.sort = _t.first);
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.paginator = _t.first);
        }
      },
      inputs: {
        tableConfiguration: "tableConfiguration",
        tableData: "tableData",
        isFilterApplied: "isFilterApplied",
        SearchEvent$: "SearchEvent$",
        currencyCode: "currencyCode"
      },
      outputs: {
        searchedText: "searchedText",
        rowInfo: "rowInfo"
      },
      features: [i0.ɵɵNgOnChangesFeature],
      decls: 5,
      vars: 7,
      consts: [["mat-table", "", "matSort", "", 1, "table-wrapper", 3, "dataSource"], [3, "matColumnDef", 4, "ngFor", "ngForOf"], ["mat-header-row", "", 4, "matHeaderRowDef"], ["mat-row", "", 4, "matRowDef", "matRowDefColumns"], ["showFirstLastButtons", "", 3, "length", "pageSize", "pageSizeOptions"], [3, "matColumnDef"], ["mat-header-cell", "", "id", "header-cell-style", "class", "tbl-currency-col", "mat-sort-header", "", 4, "matHeaderCellDef"], ["mat-cell", "", "class", "tbl-currency-col", 4, "matCellDef"], ["mat-header-cell", "", "id", "header-cell-style", "mat-sort-header", "", 1, "tbl-currency-col"], ["mat-cell", "", 1, "tbl-currency-col"], ["mat-header-cell", "", "id", "header-cell-style", "mat-sort-header", "", 4, "matHeaderCellDef"], ["mat-cell", "", 3, "click", 4, "matCellDef"], ["mat-header-cell", "", "id", "header-cell-style", "mat-sort-header", ""], ["mat-cell", "", 3, "click"], [3, "src"], ["mat-cell", "", 4, "matCellDef"], ["mat-cell", ""], ["mat-header-row", ""], ["mat-row", ""]],
      template: function TableComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "table", 0);
          i0.ɵɵtemplate(1, TableComponent_ng_container_1_Template, 4, 2, "ng-container", 1)(2, TableComponent_tr_2_Template, 1, 0, "tr", 2)(3, TableComponent_tr_3_Template, 1, 0, "tr", 3);
          i0.ɵɵelementEnd();
          i0.ɵɵelement(4, "mat-paginator", 4);
        }
        if (rf & 2) {
          i0.ɵɵproperty("dataSource", ctx.dataSource);
          i0.ɵɵadvance();
          i0.ɵɵproperty("ngForOf", ctx.tableConfiguration == null ? null : ctx.tableConfiguration.mapper);
          i0.ɵɵadvance();
          i0.ɵɵproperty("matHeaderRowDef", ctx.tableConfiguration == null ? null : ctx.tableConfiguration.columnList);
          i0.ɵɵadvance();
          i0.ɵɵproperty("matRowDefColumns", ctx.tableConfiguration == null ? null : ctx.tableConfiguration.columnList);
          i0.ɵɵadvance();
          i0.ɵɵproperty("length", ctx.tableConfiguration == null ? null : ctx.tableConfiguration.mapper.length)("pageSize", ctx.tableConfiguration == null ? null : ctx.tableConfiguration.pageSize)("pageSizeOptions", ctx.tableConfiguration == null ? null : ctx.tableConfiguration.pageSizeOptions);
        }
      },
      dependencies: [i1.NgForOf, i2.MatTable, i2.MatHeaderCellDef, i2.MatHeaderRowDef, i2.MatColumnDef, i2.MatCellDef, i2.MatRowDef, i2.MatHeaderCell, i2.MatCell, i2.MatHeaderRow, i2.MatRow, i3.MatPaginator, i4.MatSort, i4.MatSortHeader, NumberSuffixPipe],
      styles: [".table-wrapper[_ngcontent-%COMP%]{margin-top:5px;width:100%}#header-cell-style[_ngcontent-%COMP%]{background-color:#edf4fa}.mat-mdc-header-cell[_ngcontent-%COMP%]{color:#0051a1}.mat-mdc-row[_ngcontent-%COMP%]:nth-child(2n){background-color:#fff}.mat-mdc-row[_ngcontent-%COMP%]:nth-child(odd){background-color:#fafbfc}.mat-mdc-row[_ngcontent-%COMP%]   .mat-mdc-cell[_ngcontent-%COMP%]{border-bottom:1px solid transparent;border-top:1px solid transparent;cursor:pointer}.mat-mdc-row[_ngcontent-%COMP%]:hover   .mat-mdc-cell[_ngcontent-%COMP%]{border-color:currentColor}.tbl-currency-col[_ngcontent-%COMP%]{text-align:right!important}  .mat-sort-header-arrow{--mat-sort-arrow-color: #006BD3;min-width:16px}  .mat-sort-header-content{text-align:left!important}  .tbl-currency-col .mat-sort-header-content{text-align:right!important}  .mat-mdc-paginator-icon{fill:#0051a1!important}  .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon{fill:gray!important}.form-container[_ngcontent-%COMP%]{min-width:150px;width:100%;display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;gap:1rem}.form-container-wrapper[_ngcontent-%COMP%]{width:100%}.input-style[_ngcontent-%COMP%]{height:35px}.search-icon[_ngcontent-%COMP%]{height:55px}.rounded-field[_ngcontent-%COMP%], .rounded-field[_ngcontent-%COMP%]   .mat-form-field-outline[_ngcontent-%COMP%]{border-radius:25px}.filter-container[_ngcontent-%COMP%]{display:flex;gap:16px;margin-bottom:16px}.results[_ngcontent-%COMP%]{margin-top:16px}.filter-container[_ngcontent-%COMP%]{display:flex;flex-direction:row;gap:1rem;align-items:center}.form-container-wrapper[_ngcontent-%COMP%], .rounded-field[_ngcontent-%COMP%]{min-width:200px}.input-style[_ngcontent-%COMP%]{width:100%}.filter-row[_ngcontent-%COMP%]{display:flex;flex-direction:row;gap:1rem}.search-filter[_ngcontent-%COMP%]{width:45rem}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TableComponent, [{
    type: Component,
    args: [{
      selector: 'lib-one-customer-table',
      template: "<table mat-table [dataSource]=\"dataSource\" matSort class=\"table-wrapper\">\n    <ng-container *ngFor=\"let column of tableConfiguration?.mapper\" [matColumnDef]=\"column.fieldName\">\n      @switch (column.dataTypeColumn) {\n        @case (columnDataType.Currency) {\n        <th mat-header-cell id=\"header-cell-style\" class=\"tbl-currency-col\" *matHeaderCellDef mat-sort-header>{{column.displayColumn}}</th>\n        <td mat-cell *matCellDef=\"let element\" class='tbl-currency-col'>{{element[column.fieldName]|\n          numberSuffix:transformationType.withCurrency : currencyCode }}</td>\n        }\n        @case (columnDataType.Link) {\n        <th mat-header-cell id=\"header-cell-style\" *matHeaderCellDef mat-sort-header>{{column.displayColumn}}</th>\n        <td mat-cell *matCellDef=\"let element\" (click)=\"viewMore(element)\"> <img [src]=\"tableConfiguration.img\" /> </td>\n        }\n        @default () {\n        <th mat-header-cell id=\"header-cell-style\" *matHeaderCellDef mat-sort-header>{{column.displayColumn}}</th>\n        <td mat-cell *matCellDef=\"let element\">{{element[column.fieldName]}}</td>\n        }\n        }    \n    </ng-container>\n    <tr mat-header-row *matHeaderRowDef=\"tableConfiguration?.columnList\"></tr>\n    <tr mat-row *matRowDef=\"let row; columns: tableConfiguration?.columnList;\"></tr>\n  </table>\n  <mat-paginator [length]=\"tableConfiguration?.mapper.length\" [pageSize]=\"tableConfiguration?.pageSize\"\n    [pageSizeOptions]=\"tableConfiguration?.pageSizeOptions\" showFirstLastButtons></mat-paginator>",
      styles: [".table-wrapper{margin-top:5px;width:100%}#header-cell-style{background-color:#edf4fa}.mat-mdc-header-cell{color:#0051a1}.mat-mdc-row:nth-child(2n){background-color:#fff}.mat-mdc-row:nth-child(odd){background-color:#fafbfc}.mat-mdc-row .mat-mdc-cell{border-bottom:1px solid transparent;border-top:1px solid transparent;cursor:pointer}.mat-mdc-row:hover .mat-mdc-cell{border-color:currentColor}.tbl-currency-col{text-align:right!important}::ng-deep .mat-sort-header-arrow{--mat-sort-arrow-color: #006BD3;min-width:16px}::ng-deep .mat-sort-header-content{text-align:left!important}::ng-deep .tbl-currency-col .mat-sort-header-content{text-align:right!important}::ng-deep .mat-mdc-paginator-icon{fill:#0051a1!important}::ng-deep .mat-mdc-icon-button[disabled] .mat-mdc-paginator-icon{fill:gray!important}.form-container{min-width:150px;width:100%;display:flex;flex-direction:row;flex-wrap:nowrap;align-items:center;gap:1rem}.form-container-wrapper{width:100%}.input-style{height:35px}.search-icon{height:55px}.rounded-field,.rounded-field .mat-form-field-outline{border-radius:25px}.filter-container{display:flex;gap:16px;margin-bottom:16px}.results{margin-top:16px}.filter-container{display:flex;flex-direction:row;gap:1rem;align-items:center}.form-container-wrapper,.rounded-field{min-width:200px}.input-style{width:100%}.filter-row{display:flex;flex-direction:row;gap:1rem}.search-filter{width:45rem}\n"]
    }]
  }], () => [{
    type: i0.ChangeDetectorRef
  }], {
    tableConfiguration: [{
      type: Input
    }],
    tableData: [{
      type: Input
    }],
    isFilterApplied: [{
      type: Input
    }],
    SearchEvent$: [{
      type: Input
    }],
    currencyCode: [{
      type: Input
    }],
    searchedText: [{
      type: Output
    }],
    rowInfo: [{
      type: Output
    }],
    sort: [{
      type: ViewChild,
      args: [MatSort]
    }],
    paginator: [{
      type: ViewChild,
      args: [MatPaginator]
    }]
  });
})();
class MyCustomPaginatorIntl {
  constructor() {
    this.changes = new Subject();
    // For internationalization, the `$localize` function from
    // the `@angular/localize` package can be used.
    this.firstPageLabel = `First page`;
    this.itemsPerPageLabel = `Items per page:`;
    this.lastPageLabel = `Last page`;
    // You can set labels to an arbitrary string too, or dynamically compute
    // it through other third-party internationalization libraries.
    this.nextPageLabel = 'Next page';
    this.previousPageLabel = 'Previous page';
  }
  getRangeLabel(page, pageSize, length) {
    let startIndex = 0;
    if (length == 0) {
      startIndex = page * pageSize;
    } else {
      startIndex = page * pageSize + 1;
    }
    const endIndex = Math.min((page + 1) * pageSize, length);
    return `${startIndex} – ${endIndex} of ${length}`;
  }
  static {
    this.ɵfac = function MyCustomPaginatorIntl_Factory(t) {
      return new (t || MyCustomPaginatorIntl)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: MyCustomPaginatorIntl,
      factory: MyCustomPaginatorIntl.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MyCustomPaginatorIntl, [{
    type: Injectable
  }], null, null);
})();

// AoT requires an exported function for factories
function HttpLoaderFactory(httpClient) {
  return new TranslateHttpLoader(httpClient);
}
class OneCustomerUiTableModule {
  static {
    this.ɵfac = function OneCustomerUiTableModule_Factory(t) {
      return new (t || OneCustomerUiTableModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: OneCustomerUiTableModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [{
        provide: MatPaginatorIntl,
        useClass: MyCustomPaginatorIntl
      }],
      imports: [CommonModule, HttpClientModule, MatToolbarModule, MatIconModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule, TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OneCustomerUiTableModule, [{
    type: NgModule,
    args: [{
      declarations: [TableComponent, NumberSuffixPipe],
      // Declare components, directives, and pipes
      imports: [CommonModule, HttpClientModule, MatToolbarModule, MatIconModule, MatMenuModule, MatTableModule, MatPaginatorModule, MatSortModule, TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      })],
      // Import Angular modules required
      providers: [{
        provide: MatPaginatorIntl,
        useClass: MyCustomPaginatorIntl
      }],
      exports: [TableComponent, NumberSuffixPipe] // Export components, directives, or pipes for public use
    }]
  }], null, null);
})();

/*
 * Public API Surface of one-customer-ui-lib-table
 */

/**
 * Generated bundle index. Do not edit.
 */

export { HttpLoaderFactory, NumberSuffixPipe, OneCustomerUiTableModule, TableComponent };
