import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ITableConfiguration } from '../../interfaces/ITable';
import { UI_CONTROL_DEFAULT_VALUE_ALL, PROGRAM_TABLE, TABLE_SEARCH, UOM, USD, PROGRAM_MAPPER, generic_tableConfiguration,Loading,PROGRAM_NOT_FOUND_MSG, ERROR_ICON } from '../../../../shared/constants/app.constants';
import { FormControl } from '@angular/forms';
import {
  combineLatest,
  distinctUntilChanged,
  forkJoin,
  map,
  Observable,
  Subject,
  Subscription,
} from 'rxjs';
import { CustomerService } from '../../services/customer.service';
import {
  IProgramDetails,
  IProgramFilters,
  IProgramTableResponse,
} from '../../interfaces/IProgram.interface';
import { FilterService } from '../../services/filter.service';
import { UserPreferenceService } from '../../services/user-preference.service';
import { ILDetailsRequest } from '../../interfaces/IDetailsL1';
import { CustomerNameErrorStateMatcher } from '../../classes/customerNameTextMatcher';
import { TableComponent } from '@reusable-lib/one-customer-ui-lib-table';
import { CustomerApi } from '../../interfaces/customer.interface';
@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit, AfterViewInit, OnDestroy {
  programTableConfiguration: ITableConfiguration;
  programFilters: any = [];
  programTableData: any = [];
  filteredData: IProgramDetails[];
  statusFilters: string[] = [];
  valueCategoryFilters: string[] = [];
  searchedRecord$: Subject<string> = new Subject();
  searchControl = new FormControl('');

  TABLE_ASSETS = TABLE_SEARCH;
  imgIcon: string = PROGRAM_TABLE.ICON;
  errorIcon: string = ERROR_ICON;
  selectedStage: string = UI_CONTROL_DEFAULT_VALUE_ALL;
  selectedValueCategory: string = UI_CONTROL_DEFAULT_VALUE_ALL;
  DEFAULT_VALUE = UI_CONTROL_DEFAULT_VALUE_ALL;

  searchLabel: string = '';
  textToSearch: string = '';
  private requestFilters: IProgramFilters;
  customerID: string;
  unitOfMeasure: string = UOM;
  currencyCode: string = USD;
  matcher = new CustomerNameErrorStateMatcher();
  programErrorText = PROGRAM_NOT_FOUND_MSG;
  _isFilterLoading: boolean = true;
  _loadingText = Loading;
  @ViewChild('tableComponent') tableComponent: TableComponent;
  private subscription: Subscription;
  constructor(
    private customerService: CustomerService,
    public _filterService: FilterService,
    public _userPreferenceService: UserPreferenceService,
    private detect: ChangeDetectorRef) { }

  ngOnInit() {
    this.getUserPreferences();
    this.generate_Programs_Configuration();
    this.customerService.resetTableOncustNameChange.subscribe((resetStatus)=>{
      if(resetStatus) {
        this.tableComponent.makeChanges();
        this.detect.detectChanges();
      }
    });
    this._userPreferenceService
      .getUserPreferencesObservable()
      .subscribe((preferences) => {
        if (preferences) {
          this.currencyCode = preferences.currency;
          this.currencyCode = preferences.currency;
        }
      });
    this.searchLabel = `${this.TABLE_ASSETS.LABEL} Program Name`;
    this.fetchProgramsAndFilters();
  }

  ngAfterViewInit(): void {
    this.customerService.isFilterApplied$
      .pipe(distinctUntilChanged())
      .subscribe((isApplied) => {
        if (isApplied) {
          this.tableComponent.makeChanges();
          this.tableComponent.resetSortState();
        }
      });
  }

  /**
   * Updates the status and value category filters based on the input programFilters.
   */
  private updateFilters(): void {
    const responseArray = this.programFilters?.response?.response || [];
    // Reset filters
    this.statusFilters = [];
    this.valueCategoryFilters = [];
    // Populate status and valueCategory filters
    responseArray.forEach((filterObj) => {
      if (filterObj.filterType === 'status') {
        this.statusFilters = filterObj.filters;
      } else if (filterObj.filterType === 'valueCategories') {
        this.valueCategoryFilters = filterObj.filters.filter((filter) => filter !== null);
      }
    });
  }

  /**
   * Applies filters to the program data based on the selected stage and value category.
   * Fetches the filtered data from the customer service.
   */
  applyFilters(): void {
    this._isFilterLoading = true;
    if (!Array.isArray(this.programTableData)) {
      this.filteredData = [];
      this.programTableData = [];
      return;
    }
    this.fetchProgramsInfo().subscribe(
      (response) => {
        this.handleProgramTableDataResponse(response);
        this._isFilterLoading = false;
      },
      (error) => {
        console.error(
          'Error occurred from Programs Component API Calls:',
          error,
        );
        this._isFilterLoading = false;
      },
    );
  }

  /**
   * Handles the 'All' selection logic for the filters (currentStatus and valueCategories).
   */
  private handleFilterSelections(): void {
    // Handle 'All' selection for currentStatus (selectedStage)
    if (this.selectedStage !== UI_CONTROL_DEFAULT_VALUE_ALL) {
      this.requestFilters.currentStatus = this.selectedStage;
    } else {
      delete this.requestFilters.currentStatus;
    }

    // Handle 'All' selection for valueCategories (selectedValueCategory)
    if (this.selectedValueCategory !== UI_CONTROL_DEFAULT_VALUE_ALL) {
      this.requestFilters.valueCategories = this.selectedValueCategory;
    } else {
      delete this.requestFilters.valueCategories;
    }
  }

  searchRecords(event: Event) {
    this.textToSearch = (event.target as HTMLInputElement).value;
    if (this.textToSearch) {
      this.programTableData = this.filteredData
        .filter(data => data.programName?.toLowerCase().includes(this.textToSearch.trim().toLowerCase()));
        this.validateProgramTableData();
    } else {
      this.fetchProgramsInfo().subscribe(
        (response) => {
          this.handleProgramTableDataResponse(response);
        },
        (error) => {
          console.error(
            'Error occurred from Programs Component API Calls:',
            error,
          );
        },
      );
    }
    this.searchedRecord$.next(this.programTableData);
  }

  resetSearch() {
    this.searchControl.setValue(null);
    this.textToSearch = '';
    if(this.filteredData){
      this.programTableData = this.filteredData;
    }
  }

  /**
   * Method used to get the customer programs from the data source.
   */
  fetchProgramsInfo(): Observable<IProgramTableResponse> {
    //this._isFilterLoading = true; // Start loading
    this.filteredData = [];
    const basereqPayload: ILDetailsRequest =
      this._filterService.getAppliedOptionalFilters(
        this.unitOfMeasure,
        this.currencyCode,
      );
    this.requestFilters = basereqPayload;
    this.handleFilterSelections();
    return this.customerService.getCustomerPrograms(
      this.customerID,
      this.requestFilters,
    );
  }

  private handleProgramTableDataResponse(data: IProgramTableResponse) {
    this.filteredData = data?.response?.programInformation || [];
    this.programTableData = this.filteredData.filter((program) =>
      program.programName
        ?.toLowerCase()
        .includes(this.textToSearch.trim().toLowerCase()),
    );
    this.validateProgramTableData();
  }

  /**
   * Validates the program table data and sets errors if necessary.
   */
  private validateProgramTableData(): void {
    if(this.textToSearch){
      this.programTableData.length === 0 ? this.searchControl.setErrors({ 'invalid': true }) : this.searchControl.setErrors(null);
    }    
  }

  /**
   * Method used to get the program filters
   * @param customerId Customer ID
   * @param programFiltersReqObj request filters associated to get the program filters
   */
  fetchProgramFilters(): Observable<CustomerApi> {
    this.programFilters = {};
    //this._isFilterLoading = true; // Start loading
    if (!this.customerID) {
      this._isFilterLoading = false; // Stop loading if no customerID
      return new Observable<CustomerApi>();
    }
    this.resetFilters();
    const basereqPayload: ILDetailsRequest =
      this._filterService.getAppliedOptionalFilters(
        this.unitOfMeasure,
        this.currencyCode,
      );
    this.requestFilters = basereqPayload;
    this.handleFilterSelections();
    return this._filterService.getProgramFilters(
      this.customerID,
      basereqPayload,
    );
  }

  private handleProgramFiltersDataResponse(data: CustomerApi) {
    this.programFilters = data;
    this.updateFilters();
  }

  /**
   * Method used to get the user preferences from the data source.
   */
  getUserPreferences(): any {
    this._userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
      if (preferences) {
        this.currencyCode = preferences.currency;
        this.unitOfMeasure = preferences.uom;
      }
    })
  }

  /**
   * Method used to generate the Programs configuration required for the component
   */
  generate_Programs_Configuration() {
    let columnList = PROGRAM_MAPPER.map(item => item.fieldName);
    this.programTableConfiguration = {
      columnList: columnList,
      mapper: PROGRAM_MAPPER,
      tableTitle: PROGRAM_TABLE.TITLE,
      tableType: PROGRAM_TABLE.TABLE_TYPE_CUSTOMER_PROGRAMS,
      pageSizeOptions: generic_tableConfiguration.pageSizeOptions,
      pageSize: generic_tableConfiguration.pageSize
    }
  }

  resetFilters() {
    this.selectedStage = UI_CONTROL_DEFAULT_VALUE_ALL;
    this.selectedValueCategory = UI_CONTROL_DEFAULT_VALUE_ALL;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  fetchProgramsAndFilters(): void {
    this.subscription = combineLatest([
      this.customerService.isFilterApplied$,
      this._filterService.customerInfo.pipe(distinctUntilChanged()),
    ]).subscribe(([_isFilterApplied$, _customerInfo$]) => {
      if (_isFilterApplied$) {
        this._isFilterLoading = true; // Start loading
        this.customerID = _customerInfo$.customerID;
        const fetchProgramsInfo$ = this.fetchProgramsInfo().pipe(
          map((response) => {
            this.handleProgramTableDataResponse(
              response as IProgramTableResponse,
            );
          }),
        );
        const fetchProgramFilters$ = this.fetchProgramFilters().pipe(
          map((response) => {
            this.handleProgramFiltersDataResponse(response as CustomerApi);
          }),
        );
        forkJoin([fetchProgramsInfo$, fetchProgramFilters$]).subscribe(
          (): void => {
            this._isFilterLoading = false; // Hide the loader
          },
          (error) => {
            console.error(
              'Error occurred from Programs Component API Calls:',
              error,
            );
            this._isFilterLoading = false; // Hide the loader even if there's an error
          },
        );
      }
    });
  }
}
