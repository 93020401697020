import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ITableConfiguration } from '../../interfaces/ITable';
import { BIC_MAPPER, BIC_TABLE, ENERGY, generic_tableConfiguration, COST_AND_BENEFITS, UI_CONTROL_DEFAULT_VALUE_ALL, UOM, USD, WASTE, WATER, Loading } from '../../../../shared/constants/app.constants';
import { TranslationService } from '../../services/translation.service';
import { TranslateService } from '@ngx-translate/core';
import { IValueCategoryTiles } from '../../interfaces/IValueCategoryTiles.interface';
import { FormControl } from '@angular/forms';
import { CustomerService } from '../../services/customer.service';
import { FilterService } from '../../services/filter.service';
import { UserPreferenceService } from '../../services/user-preference.service';
import { IBICInfo, IOfferingCalculatedValues, ValueCategoryInfo } from '../../interfaces/IBIC';
import { MatDialog } from '@angular/material/dialog';
import { TableComponent } from '@reusable-lib/one-customer-ui-lib-table';

@Component({
  selector: 'app-best-in-class',
  templateUrl: './best-in-class.component.html',
  styleUrl: './best-in-class.component.scss'
})
export class BestInClassComponent implements AfterViewInit{
  @Input() BIC_Configuration: ITableConfiguration = null;
  @Output() siteFlag: EventEmitter<{ flag: boolean, bicRowInfo: IOfferingCalculatedValues }> = new EventEmitter();
  bicTableData: IOfferingCalculatedValues[];
  bicInfo: IBICInfo;
  valCategoryFormControl = new FormControl(UI_CONTROL_DEFAULT_VALUE_ALL);
  isFilterApplied: boolean = false;
  valueCategoryInfo: ValueCategoryInfo = { valueCategoryLst: [], isReceived: false };
  bicAsset = BIC_TABLE;
  tilesData: IValueCategoryTiles[] = [];
  customerID: string;
  valueCategorySelection: boolean = false;
  subscribed_customerID: string;
  loader: boolean = true;
  _loadingText = Loading;
  isFilterLoading:boolean=false;
  currency: string;
  @ViewChild('tableComponent') tableComponent: TableComponent;
  constructor(
    public translateService: TranslationService,
    public _userPreferenceService: UserPreferenceService,
    public translate: TranslateService,
    public _customerService: CustomerService,
    public _filterService: FilterService,
    public dialog: MatDialog,
    private detect: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.fetchBICInfo();
    this.generate_BIC_Configuration();
    let customerID = this._filterService?.customerInfo?.value?.customerID;
    if (customerID) {
      this._userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
        if (preferences) {
          this.currency = preferences.currency;
        }
    });
}
    this._customerService?.resetTableOncustNameChange?.subscribe((resetStatus)=>{
      if(resetStatus && this.tableComponent) {
        this.tableComponent.makeChanges();
        this.detect.detectChanges();
      }

    })
    this._customerService.isFilterApplied$.subscribe(
      val => {
        if (val) {
          this.valCategoryFormControl.setValue(UI_CONTROL_DEFAULT_VALUE_ALL);
          this.isFilterApplied = val;
        }
      }
    )
    this._filterService._filterLoadingSubject.subscribe(
      res=>
      {
        this.isFilterLoading=res; 
      });
  }

  displayViewMore(rowInfo: IOfferingCalculatedValues) {
    this.siteFlag.emit({ flag: true, bicRowInfo: rowInfo });
  }

  applyFilter() {   
    const selectedValCtgryFilter = this.valCategoryFormControl.value;
    this.valueCategorySelection = true;
    if (selectedValCtgryFilter) {
      this._filterService.setBICValueCategory(selectedValCtgryFilter, true);
    }
    this.valueCategorySelection = false;
  }

  ngAfterViewInit(): void {
    this._customerService.isFilterApplied$.subscribe(
     isApplied => {
       if (isApplied && this.tableComponent) {
         this.tableComponent.makeChanges();
         this.tableComponent.resetSortState();
       }
     })
 }

  extractValueCategoryInfo() {
    this.tilesData =
      Object.keys(this.bicInfo.valueCategoryAggregatedInfo)
        .map(key => {
          let valueCategorytitle: string;
          switch (key) {
            case "waterValueCategorySum":
              valueCategorytitle = WATER;
              break;
            case "wasteValueCategorySum":
              valueCategorytitle = WASTE;
              break;
            case "energyValueCategorySum":
              valueCategorytitle = ENERGY;
              break;
            default:
              valueCategorytitle = COST_AND_BENEFITS;
              break;
          }
          // valueCategorytitle = key === "othersValueCategorySum" ? COST_AND_BENEFITS : key;
          const value = this.bicInfo.valueCategoryAggregatedInfo[key];
          return {
            valueCategorytitle,
            value
          };
        }).sort((a, b) => {
          const order = [WATER, ENERGY, WASTE, COST_AND_BENEFITS];
          return order.indexOf(a.valueCategorytitle) - order.indexOf(b.valueCategorytitle);
        });
  }

  isFetchEligible() {
    const custID = this._filterService.customerInfo.value?.customerID;
    const isSameCustomer = this.customerID === custID;
    const shouldFetch = isSameCustomer && this.valueCategorySelection;
    if (shouldFetch) {
      this.fetchBICInfo();
    }
    else if (!isSameCustomer) {
      this._filterService.bicValueCategoryList$.next([]); // clearing the BehaviorSubject once customer is reset
      this.customerID = custID;
      this.valCategoryFormControl.setValue(UI_CONTROL_DEFAULT_VALUE_ALL);
      this.valueCategoryInfo.isReceived = false;
      this.fetchBICInfo();
    }
    else if (this.isFilterApplied) {
      this.customerID = custID;
      this.fetchBICInfo();
      this.isFilterApplied = false;
    }
  }

  fetchBICInfo() {
    this._customerService.bicInfo$.subscribe(
      res => {
        const valCategory = this._filterService.getBICValueCategory();
        this.bicInfo = res;
        if (!this.bicInfo)
          return;
        this.bicTableData = this.bicInfo.offeringCalculatedValues;
        this.AddSiteViewColumn();
        this.extractValueCategoryInfo();
        this.generate_BIC_Configuration();
        this.sortBicTableData();// Sort the BIC table data in descending order by totalPotentialValue
        if (valCategory === UI_CONTROL_DEFAULT_VALUE_ALL) {
          this.valueCategoryInfo.valueCategoryLst = [UI_CONTROL_DEFAULT_VALUE_ALL, ...this.bicInfo.valueCategories];
          this._filterService.bicValueCategoryList$.next([...this.bicInfo.valueCategories])
        }
        else
          this.valueCategoryInfo.valueCategoryLst = [UI_CONTROL_DEFAULT_VALUE_ALL, ...this._filterService.bicValueCategoryList$.value];
        this.loader = false;
        this.isFilterLoading = false;
      });
  }

  AddSiteViewColumn() {
    this.bicTableData = this.bicTableData.map(item => ({
      ...item,
      viewMoreColumn: '' //Adding Extra column to view Modal Icon
    }));
  }
  generate_BIC_Configuration() {
    let columnList = BIC_MAPPER.map(item => item.fieldName);
    this.BIC_Configuration = {
      columnList: columnList,
      mapper: BIC_MAPPER,
      tableTitle: BIC_TABLE.TITLE,
      tableType: BIC_TABLE.TABLE_TYPE,
      pageSizeOptions: generic_tableConfiguration.pageSizeOptions,
      pageSize: BIC_TABLE.DEFAULT_PAGESIZE,
      img: BIC_TABLE.ICON
    }
  }
  /**
  * Sorts the bicTableData array in descending order based on the totalPotentialValue property.
  * Ensures the highest totalPotentialValue appears first.
  */
  sortBicTableData() {
    if (this.bicTableData && Array.isArray(this.bicTableData)) {
      this.bicTableData.sort((firstItem, secondItem) =>
        secondItem.bicPotentialValue - firstItem.bicPotentialValue
      );
    }
  }
}
