<div class="valueMetric-main">
  <div class="valueMetric-main-section">
    <div class="valueMetric-main-section-heading">
      <h3>{{VALUE_ACTIVITY_METRICS}}</h3>
      <button *ngIf="isNew >= 1 || isNew === null"
        class="valueMetric-main-section-modify desktop-toggle ripple_disabled"
        (click)="valueActiveMetric(DIALOG_MODE_MODIFY)">
        <span class="valueMetric-main-section-modify-button">
          {{MODIFY}} <img [src]='EDIT' alt="edit icon">
        </span>
      </button>

      <button *ngIf="isNew >= 1 || isNew === null" mat-icon-button
        class="toggle-button"
        [matMenuTriggerFor]="mobileMenu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #mobileMenu="matMenu" class="mat-menu">
        <button class="mobile-menu" mat-menu-item
          (click)="valueActiveMetric(DIALOG_MODE_MODIFY)">
          <span class="mat-menu-button">
            {{MODIFY}} <img [src]='EDIT' alt="edit icon">
          </span>
        </button>
      </mat-menu>

    </div>
    <div
      [ngClass]="isNew === 0 || isNew === null  ? 'valueMetric-main-section-wrapper--blur':'valueMetric-main-section-wrapper'">
      <div
        [ngClass]="isNew === 0 ||isNew === null ? 'valueMetric-main-section-container':'valueMetric-main-section-metricBoxHeight'">
        <div *ngIf="isNew === 0 || isNew === null"
          class="valueMetric-main-section-metric-border">
          <div class="valueMetric-main-section-add">
            <img [src]='ADD_NULL' alt="add null icon">
            <h1>{{'TVD.NULLSTATE_VAM_1'| translate}}</h1>
            <p>{{'TVD.NULLSTATE_VAM_2'| translate}}</p>
            <button mat-raised-button
              (click)="valueActiveMetric(DIALOG_MODE_ADD)">
              <span class="add_plus"><img [src]="PLUS_ICON_WHITE" alt="add data white color icon">{{
                'TVD.ADD_DATA' | translate}}</span>
            </button>
          </div>
        </div>
        <div>
          <div *ngIf="isNew" class="valueMetric-main-section-metricBox">
            <div *ngFor="let metricValueTileData of boxdata"
              class="valueMetric-main-section-metricBox-metricTiles">
              <h1>{{metricValueTileData.metricValue}}</h1>
              <p
                [title]="metricValueTileData.metricName">{{metricValueTileData.metricName}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
