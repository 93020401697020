<div class="table-container mat-elevation-z8">
  <div class="title">
    <img [src]="imgIcon" />
    <h3>{{ programTableConfiguration?.tableTitle }}</h3>
  </div>
  <br><br>
  <mat-divider></mat-divider>
  <br>
  <form class="form-container">
    <div class="search-filter">
      <mat-form-field appearance="outline" class="form-container-wrapper">
        <mat-label>{{ searchLabel }}</mat-label>
        <mat-icon matPrefix class="search-icon">search</mat-icon>
        @if(searchControl.getError('invalid'))
        {
            <mat-icon matSuffix><img [src]="errorIcon" alt="Error Icon" /></mat-icon>
        }
        @else
        {
          <mat-icon class="close-icon" *ngIf="searchControl.value" matSuffix (click)="resetSearch()">highlight_off</mat-icon>
        }         
        <input class="input-style" [formControl]="searchControl" type="text" matInput (keyup)="searchRecords($event)" (input)="searchRecords($event)" [errorStateMatcher]="matcher"/>
        @if(searchControl.getError('invalid'))
    {
    <mat-error>{{programErrorText}}</mat-error>
    }
      </mat-form-field>
    </div>

    <!-- Filters Row -->
    <div class="filter-row">
      <!-- Stage Filter -->
      <mat-form-field appearance="outline" class="rounded-field">
        <mat-label>Stage</mat-label>
        <mat-select [(ngModel)]="selectedStage" [ngModelOptions]="{standalone: true}"
          (selectionChange)="applyFilters()">
          <!-- Show loader inside the dropdown -->
          <!-- <mat-option *ngIf="_isFilterLoading" class="dropdown-loader">
            <div class="loader-container">
              <span class="spinner"></span>
              <span class="loading-text">{{_loadingText}}</span>
            </div>
          </mat-option> -->
          <mat-option [value]="DEFAULT_VALUE" *ngIf="!_isFilterLoading">{{ DEFAULT_VALUE }}</mat-option>
          <mat-option *ngFor="let option of statusFilters" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>

      <!-- Value Category Filter -->
      <mat-form-field appearance="outline" class="rounded-field">
        <mat-label>Value Category</mat-label>
        <mat-select [(ngModel)]="selectedValueCategory" [ngModelOptions]="{standalone: true}"
          (selectionChange)="applyFilters()">
          <!-- Show loader inside the dropdown -->
          <!-- <mat-option *ngIf="_isFilterLoading" class="dropdown-loader">
            <div class="loader-container">
              <span class="spinner"></span>
              <span class="loading-text">{{_loadingText}}</span>
            </div>
          </mat-option> -->
          <mat-option [value]="DEFAULT_VALUE" *ngIf="!_isFilterLoading">{{ DEFAULT_VALUE }}</mat-option>
          <mat-option *ngFor="let option of valueCategoryFilters" [value]="option">{{ option }}</mat-option>
        </mat-select>
      </mat-form-field>
      <div class="loader-container" *ngIf="_isFilterLoading">
        <span class="spinner"></span>
        <span class="loading-text">{{_loadingText}}</span>
      </div>
    </div>
  </form>

  <!-- Table Component -->
   <lib-one-customer-table [tableData]="programTableData" [SearchEvent$]="searchedRecord$" [currencyCode]= "currencyCode" [tableConfiguration]="programTableConfiguration" (searchedText)="resetSearch()" #tableComponent ></lib-one-customer-table>
</div>
