import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { BIC_LOCATION_MAPPER, BIC_MODAL, BIC_TABLE, generic_tableConfiguration } from '../../../../shared/constants/app.constants';
import { ITableConfiguration } from '../../interfaces/ITable';
import { DialogRef } from '@angular/cdk/dialog';
import { IBICSiteDetail, IBICSiteResponse, IOfferingCalculatedValues } from '../../interfaces/IBIC';
import { FilterService } from '../../services/filter.service';
import { UserPreferenceService } from '../../services/user-preference.service';
import { CustomerService } from '../../services/customer.service';
import { TableComponent } from '@reusable-lib/one-customer-ui-lib-table';

@Component({
  selector: 'app-modal-view',
  templateUrl: './modal-view.component.html',
  styleUrl: './modal-view.component.scss'
})
export class ModalViewComponent implements AfterViewInit{

  table_Configuration: ITableConfiguration = null;
  locationInfo:IBICSiteDetail;
  rowInfo:IOfferingCalculatedValues;
  BIC_LITERALS=BIC_MODAL;
  uom:string;
  currency:string;
  customerName: string = '';
  @ViewChild('tableComponent') tableComponent: TableComponent;
  constructor(private _customerService:CustomerService, private _userPreferenceService:UserPreferenceService, private detect: ChangeDetectorRef, public dialogRef: DialogRef<ModalViewComponent>, private _filterService:FilterService){}

  ngOnInit()
  {
    this.rowInfo=this.dialogRef?.config?.data;
    this.generate_BIC_Configuration();
    this.fetchLocationInfo();
    this._customerService.resetTableOncustNameChange.subscribe((resetStatus)=>{
      if(resetStatus) {
        this.tableComponent.makeChanges();
        this.detect.detectChanges();
      }
    })
    this._filterService.customerInfo.subscribe(custInfo => {
      if (!this.customerName || custInfo?.customerName.toLowerCase() !== this.customerName.toLowerCase()) {
        // this.makeChanges();
        this.customerName = custInfo?.customerName;
        // this.detct.detectChanges();
        this._customerService.resetTableOncustNameChange.next(true);
      }
    });
  }

  ngAfterViewInit(): void {
    this._customerService.isFilterApplied$.subscribe(
     isApplied => {
       if (isApplied) {
         this.tableComponent.makeChanges();
         this.tableComponent.resetSortState();
       }
     })
 }

  fetchLocationInfo()//mock API call
  {
      let customerID = this._filterService.customerInfo.value.customerID;
      if (customerID) {
        this._userPreferenceService.getUserPreferencesObservable().subscribe(preferences => {
          if (preferences) {
            this.uom = preferences.uom;
            this.currency = preferences.currency;
          }
        
        const reqPayload = this._filterService.getAppliedOptionalFilters(this.uom, this.currency,true);
        const encodedOfferingName=btoa(this.rowInfo.offeringName);//converting string to base64
        this._customerService.fetchBICLocation<IBICSiteResponse>(customerID, reqPayload, encodedOfferingName).subscribe(
          res => {
            this.locationInfo = res;
          });
      });
  }
}

  generate_BIC_Configuration() {
    let columnList = BIC_LOCATION_MAPPER.map(item => item.fieldName);
    this.table_Configuration = {
      columnList: columnList,
      mapper: BIC_LOCATION_MAPPER,
      tableTitle: '',
      tableType: '',
      pageSizeOptions: generic_tableConfiguration.pageSizeOptions,
      pageSize: BIC_TABLE.DEFAULT_PAGESIZE,
      img: BIC_TABLE.ICON
    }
  }

  closeModel() {
    this.dialogRef.close();
  }

}
