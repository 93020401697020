import { ElementRef, Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { BehaviorSubject, Observable, Subject, catchError, throwError } from 'rxjs';
import { IL1DetailsResponse, IL2DetailsResponse, ILDetailsRequest } from '../interfaces/IDetailsL1';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CustomerApi, ICustomerFilters } from '../interfaces/customer.interface';
import { Catogory, MetricData, Metrics } from '../interfaces/metrics.interface';
import { MsalService } from '@azure/msal-angular';
import { IProgramFilters, IProgramTableResponse } from '../interfaces/IProgram.interface';
import { USD, UOM } from '../../../shared/constants/app.constants';
import { IBICInfo } from '../interfaces/IBIC';

@Injectable({ providedIn: 'root' })
export class CustomerService {
  public errorOccured: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public resetTableOncustNameChange: Subject<boolean> = new Subject();
  public isFilterApplied$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isBICFlag$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public bicInfo$: BehaviorSubject<IBICInfo> = new BehaviorSubject(null);

  constructor(private http: HttpClient, private authService: MsalService) {
  }

  //getCustomer function is used to get customer details
  getCustomerList(): Observable<CustomerApi> {
    return this.http.get<CustomerApi>(environment.API_URL_MULESOFT).pipe(catchError(this.errorHandler));
  }

  getMetricsList(userid: string, customerid: string): Observable<Metrics> {
    return this.http.get<Metrics>(`${environment.API_DOTNET_TVD_URL}valueactivitymetrics?userid=${userid}&customerid=${customerid}`).pipe(catchError(this.errorHandler));
  }

  createMetrics(MetricData: MetricData): Observable<Metrics> {
    return this.http.post<Metrics>(`${environment.API_DOTNET_TVD_URL}valueactivitymetrics`, MetricData).pipe(catchError(this.errorHandler));
  }

  deleteMetrics(MetricDeleteData: any): Observable<any> {
    return this.http.delete<any>(`${environment.API_DOTNET_TVD_URL}valueactivitymetrics`, { body: MetricDeleteData }).pipe(catchError(this.errorHandler));
  }

  getCatogoryList(): Observable<Catogory> {
    return this.http.get<Catogory>(`${environment.API_DOTNET_TVD_URL}valueactivitymetrics/categories`).pipe(catchError(this.errorHandler));
  }
  getDetails<T>(endPoint: string, customerID: string, detailRequest: ILDetailsRequest): Observable<T> {
    return this.http.post<T>(`${environment.API_URL_MULESOFT}/${customerID}/${endPoint}`, detailRequest).pipe(
      catchError(this.errorHandler));;
  }

  getBICDetails<T>(customerID: string, filters: ILDetailsRequest): Observable<T> {
    return this.http.post<T>(`${environment.BIC_MULESOFT}/Opportunity/api/BestInClass/${customerID}`,filters).pipe(
      catchError(this.errorHandler));
  }

  fetchBICLocation<T>(customerID:string, filters:ILDetailsRequest,offeringName:string): Observable<T>
  {    
    return this.http.post<T>(`${environment.BIC_MULESOFT_SITE}/${customerID}/Sites/${offeringName}`,filters).pipe(
      catchError(this.errorHandler));
  }

  getL1TileDetails(customerID: string, startDate?: string, endDate?: string, filterObj?: any, unitOfMeasure: string = UOM, currencyCode: string = USD): Observable<IL1DetailsResponse> {
    let customerDetail: ILDetailsRequest;
    if (filterObj) {
      customerDetail = filterObj;
    }
    else {
      customerDetail = { startDate: startDate, endDate: endDate, unitOfMeasure: unitOfMeasure, currencyCode: currencyCode };
    }
    this.isFilterApplied$.next(true);//Turning the flag ON so that necessary resets can be done
    return this.getDetails<IL1DetailsResponse>('level1', customerID, customerDetail)
  }
  getL2TileDetails(customerID: string, startDate?: string, endDate?: string, filterObj?: any, unitOfMeasure: string = UOM, currencyCode: string = USD): Observable<IL2DetailsResponse> {
    let customerDetail: ILDetailsRequest;
    if (!filterObj) {
      customerDetail = { startDate: startDate, endDate: endDate, unitOfMeasure: unitOfMeasure, currencyCode: currencyCode };
    }
    else {
      customerDetail = filterObj;
    }
    return this.getDetails<IL2DetailsResponse>('level2', customerID, customerDetail)
  }



  errorHandler = (error: HttpErrorResponse) => {
    this.errorOccured.next(true);
    console.error(error.message);
    return throwError(error.message || "Server Error");
  }

  getProgramsTable(customerFiltersObj: ICustomerFilters): Observable<IProgramTableResponse> {
    let customerDetail: ILDetailsRequest;
    if (!customerFiltersObj.additionalFilters) {
      customerDetail = {
        startDate: customerFiltersObj.startDate,
        endDate: customerFiltersObj.endDate,
        unitOfMeasure: customerFiltersObj.unitOfMeasure,
        currencyCode: customerFiltersObj.currencyCode,
        currentStatus: customerFiltersObj.currentStatus,
        valueCategories: customerFiltersObj.valueCategories
      };
    }
    else {
      customerDetail = customerFiltersObj.additionalFilters;
    }
    return this.http.post<IProgramTableResponse>(`${environment.API_URL_MULESOFT}/${customerFiltersObj.customerID}/programs`, customerDetail)
  }

  getCustomerPrograms(customerID: string, programFiltersObj: IProgramFilters): Observable<IProgramTableResponse> {
    return this.http.post<IProgramTableResponse>(`${environment.API_URL_MULESOFT}/${customerID}/programs`, programFiltersObj);
  }

}
    