import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { LANGUAGE_ENGLISH_KEY, LANGUAGE_ENGLISH_VALUE, UOM, USD } from '../../../shared/constants/app.constants';
import { UserPreferences } from '../interfaces/IuserPreference.interace';

@Injectable({ providedIn: 'root' })
export class UserPreferenceService {
  private userPreferencesSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(
    private http: HttpClient,
    private authService: MsalService) { }

  /**
   * Retrieves user preferences from the API
   *
   * @param emailId User email ID
   * @returns Observable with user preferences data
   */
  getUserPreferences(): Observable<any> {
    const emailId = this.getUserMailID();
    const url = environment.API_DOTNET_TVD_Language_URL;
    return this.http.get<any>(url, { params: { EmailId: emailId } }).pipe(
      map(response => {
        let preferences: UserPreferences = response?.data?.userPreferences;
        if (!preferences) {
          console.log('User preferences not found. Setting default preferences.');
          preferences = {
            id: -1,
            emailId: emailId,
            langKey: LANGUAGE_ENGLISH_KEY,
            language: LANGUAGE_ENGLISH_VALUE,
            currency: USD,
            uom: UOM
          }
        }
        this.userPreferencesSubject.next(preferences); // Update BehaviorSubject
        return preferences;
      }),
      catchError(this.errorHandler)
    );
  }

  //get user email from authentication service
  getUserMailID(): string {
    return this.authService.instance.getAllAccounts()[0]?.localAccountId;
  }

  /**
   * Error handler method
   *
   * @param error HttpErrorResponse object
   * @returns Observable with error message
   */
  private errorHandler(error: HttpErrorResponse): Observable<never> {
    let errorMessage = 'An unknown error occurred!';
    if (error.error instanceof ErrorEvent) {
      // Client-side or network error
      errorMessage = `Client-side error: ${error.error.message}`;
    } else {
      // Backend error
      errorMessage = `Server-side error: ${error.status} ${error.message}`;
    }
    console.error(errorMessage);
    return throwError(() => new Error(errorMessage));
  }

  /**
   * Exposes the BehaviorSubject as an observable for other components to subscribe to
   *
   * @returns Observable with user preferences data
   */
  getUserPreferencesObservable(): Observable<any> {
    return this.userPreferencesSubject.asObservable();
  }

  /**
   * Method used to get currency symbol based on currency code
   * @param currencyCode Currency code to be passed (eg., INR, USD, EUR etc)
   * @returns currency symbol as a string
   */
  getCurrencySymbol(currencyCode: string = USD): string {
    let currencySymbol: string = Intl.NumberFormat('en',
      { style: 'currency', currency: currencyCode })
      .formatToParts()
      .find(part => part.type === 'currency').value;
    return currencySymbol;
  }
}
