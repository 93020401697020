<div class="BIC-Site-Container">
  <div class="BIC-site-navbar">
    <div class="navigation-panel">
      <div class="BIC-back-btn" (click)="backToDashBoard()">
        <img class="backbtn-icon" [src]="BIC_SITE_LITERAL.BACK_ICON" />
        <div class="back-nav-title">{{BIC_SITE_LITERAL.BACKTITLE}}</div>
      </div>
      <div class="BIC-Branding">{{BIC_SITE_LITERAL.BIC_BRANDING}}</div>
    </div>
  </div>
  <div class="BIC-card-section">
    <mat-card class="table-card mat-elevation-z8" appearance="outlined">
      @if(isLoading)
      {
      <div class="loading-background">
        <mat-spinner class="loading-spinner" [diameter]="123" [strokeWidth]="8"></mat-spinner>
      </div>
      }
      <div class="offering-title">{{rowInfo.offeringName}}</div><br />
      <mat-card-content>
        <lib-one-customer-table [tableData]="locationInfo?.offeringSites" [tableConfiguration]="table_Configuration" [currencyCode] = "currency"></lib-one-customer-table>
      </mat-card-content>
    </mat-card>
  </div>
</div>
<br/>
<br/>