import { Pipe, PipeTransform } from '@angular/core';
import { LANGUAGE_ENGLISH_KEY, TRANSFORMATION_TYPE ,USD} from '../constants/app.constants';
@Pipe({
    name: 'numberSuffix'
})
export class NumberSuffixPipe implements PipeTransform {
    transform(value: any, transformationType: string = TRANSFORMATION_TYPE.withCurrency, currencyCode: string = USD, ...args: any[]): any {

        if (value === null || value === undefined) return null;
        let maxDecimals = 0;
        if (value > 999) {
            maxDecimals = 2;
        }
        switch (transformationType) {
            case TRANSFORMATION_TYPE.withCurrency:
                return this.currencyTransform(value, maxDecimals, currencyCode);
            case TRANSFORMATION_TYPE.withoutCurrency:
                return this.numberTransform(value, maxDecimals);
            default:
                return value;
        }
    }
    private currencyTransform(value: any, maxDecimals: number, currencyCode): any {
        return new Intl.NumberFormat(LANGUAGE_ENGLISH_KEY, {
            style: 'currency',
            currency: currencyCode,
            notation: "compact",
            compactDisplay: "short",
            maximumFractionDigits: maxDecimals
        }).format(value);
    }
    private numberTransform(value: any, maxDecimals: number): any {
        return new Intl.NumberFormat(LANGUAGE_ENGLISH_KEY, {
            notation: "compact",
            compactDisplay: "short",
            maximumFractionDigits: maxDecimals
        }).format(value);
    }
}
