import { Injectable } from '@angular/core';
import {
  CanLoad,
  GuardResult,
  MaybeAsync,
  Route,
  Router,
  UrlSegment} from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { MsalService } from '@azure/msal-angular';

@Injectable({ providedIn: 'root' })
export class AppAccessGuard implements CanLoad {
  isAuthorized: boolean;
  public authFlag$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    private msal: MsalService,
  ) { }

  /**
   * Determines if the route can be loaded.
   * @param route The route to be loaded.
   * @param segments The URL segments.
   * @returns An observable or boolean indicating if the route can be loaded.
   */
  canLoad(route: Route, segments: UrlSegment[]): MaybeAsync<GuardResult> {
    const url = `/${route.path}`;
    return this.checkUserLogin(url);
  }

  /**
   * Checks if the user is logged in and has access to the application.
   *
   * This method calls the `getCurrentUserAppAccess` method of the `authService` to determine
   * if the user is authorized. If the user is not authorized, they are redirected to the
   * 'unauthorized' route. If the user is authorized, their login status is updated and
   * the authorization flag is emitted.
   *
   * @returns {boolean} - Returns the authorization status of the user.
   */
  public checkUserLogin(url: string): boolean {
    this.authService
      .getCurrentUserAppAccess()
      .subscribe(data => {
        this.isAuthorized = data?.response?.isAuthorized;
        if (!this.isAuthorized) {
          this.router.navigate(['unauthorized']);
          return this.isAuthorized;
        } else {
          this.authFlag$.next(this.isAuthorized);
          this.authService.updateUserLoginStatus
            (
              this.msal.instance.getAllAccounts()[0]?.localAccountId,
              false).subscribe({});
          return this.isAuthorized;
        }
      });
    return this.isAuthorized;
  }
}
