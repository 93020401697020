<div class="valueMetricDialog">
  <h1 mat-dialog-title>{{'TVD.ADD_VALUE_ACTIVITY_METRIC' | translate}}</h1>
  <button class="valueMetricDialog-closeButton" (click)="closeModel()"><img
      [src]="CLOSE_ICON" alt="close"></button>
</div>
<mat-dialog-content class="mat-dialog-content">
  <form [formGroup]="metricsForm">
    <div formArrayName="metrics">
      <div *ngFor="let metric of metrics.controls; let i = index"
        [formGroupName]="i" class="metrics-border-h">
        <div class="metric-header">
          <h1>{{'TVD.VALUE_ACTIVITY_METRIC' | translate}} {{ i + 1 }}</h1>
          <button class (click)="deleteMetric(i,metric.value.id)">
            <img [src]="DELETE_ICON" alt="delete">
          </button>
        </div>
        <div class="w-100">
          <mat-form-field appearance="outline">
            <mat-label>{{'TVD.TYPE_OF_METRIC' | translate}}</mat-label>
            <mat-select formControlName="categoryId"
              (valueChange)="onSelectedChange($event,i)">
              <mat-option *ngFor="let catogory of typeCatogory"
                [value]='catogory.categoryId'>
                {{catogory.vamcategory}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="valueMetricDialog">
          <div class="w-100">
            <mat-form-field appearance="outline">
              <mat-label>{{'TVD.NAME_OF_METRIC' | translate}}</mat-label>
              <input matInput formControlName="metricName" #metricName required
                [readonly]="inputDisabled[i]"
                (focus)="enableManualInput(i)" maxlength="64">
              <mat-icon class="close-icon" *ngIf="!inputDisabled[i] && metricName.value" matSuffix
                  (click)="clearField(i, 'metricName')">highlight_off</mat-icon>
            </mat-form-field>
          </div>
          <div class="w-100">
            <mat-form-field appearance="outline">
              <mat-label>{{'TVD.VALUE' | translate}}</mat-label>
              <input matInput formControlName="metricValue" #metricValue
                type="text" (input)="handleInput($event , i)"
                maxlength=11>
                <mat-icon class="close-icon" *ngIf="metricValue.value" matSuffix
                  (click)="clearField(i, 'metricValue')">highlight_off</mat-icon>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div *ngIf="metrics.length === 0" class="valueMetricDialog-metric-border">
    <button class="valueMetricDialog-add" (click)="addMetric()">
      <img [src]='ADD_NULL' alt="add null icon">
      <h1>{{'TVD.NULLSTATE_VAM_1'| translate}}</h1>
      <p>{{'TVD.NULLSTATE_VAM_2'| translate}}</p>
    </button>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="mat-dialog-actions">
  <button mat-raised-button
    [ngClass]="this.metrics.length === 8 ? 'button-disabled' : 'add-metric-button'"
    [disabled]="this.metrics.length === 8" (click)="addMetric()"><span
      class="add_plus"> <span class="add_plus_icon">+</span>{{'TVD.ADD_METRIC' | translate}}</span></button>
  <button mat-raised-button color="primary" (click)="applyMetrics()"
    [disabled]="!metricsForm.valid || isApply">
    {{'TVD.APPLY' | translate}}</button>
</mat-dialog-actions>
